.Import {
    display: flex;
    flex-direction: column;
}

.TCol-FileName {
    width: 150px;
}
.TCol-FileType {
    width: 150px;
}
.TCol-Date {
    width: 120px;
}
.TCol-Records {
    width: 80px;
}
.TCol-Error {
    width: 150px;
}
.TCol-Ref {
    width: 150px;
}
.TCol-Description {
    width: 400px;
}
.TCol-Message {
    width: 150px;
}
.TCol-Progress {
    width: 400px;
}
.TCol-Log {
    width: 100px;
    overflow: auto;
}
.Align-R {
    text-align: right;
}