
.TCol-New_Bonus_Qty {
    width: 100px;
}

.TCol-New_Warrant_1_Qty {
    width: 100px;
}

.TCol-New_Warrant_2_Qty {
    width: 100px;
}

.TCol-New_Rights_Qty {
    width: 100px;
}

.TCol-New_Split_Qty {
    width: 100px;
}

.TCol-New_Consolidate_Qty {
    width: 100px;
}
