.Login {
  width: 200px;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
}

.LoginImage {
  width: 200px;
  height: 200px;
}