
/*
#ma_tr  tr:hover {background-color: #61dfd4;}
#ma_tr  tr:nth-child(even) {background-color: #d1c8f3;}
#ma_tr  tr:nth-child(even):hover {background-color: #61dfd4;}
*/

#rpt_tr  tr:hover {background-color: #61dfd4;}
//#rpt_tr  tr:nth-child(even) {background-color: #b0daee;}
//#rpt_tr  tr:nth-child(even):hover {background-color: #61dfd4;}
#rpt_tr  td {font-family: Courier New; height: 30px; white-space: pre;}

#rpt_th_r th {text-align: right;}
.RCol-Head_p th {text-align: right;}
#rpt_th_r  td {font-family: Courier New; height: 30px; white-space: pre;}

#td_center {text-align: center;}

#Err_msg{
  color: red;
}


#RPageNo2 {
  cursor:pointer;
  color:blue;
  border: 2px  #ece7e3 solid;
}

#DispText {
  color:rgb(72, 116, 236);
}

#DispText2 {
  color:#df4f5b;
}


#DispCoName {
  display: inline-block;
  width: 85%;
  //color:rgb(12, 57, 180);
  color:#FD0317
}

#DispCoName2 {
  display: inline-block;
  width: 85%;
}

.coLogo {
  /*
  width: 60px;
  height: 40px;
  */
  height: 36px;
}

#DispTrnDate {
  display: inline-block;
  font-size: 75%;
}

#inputClCode{
  color: red;
}


.WaitIcon {
  width: 120px;
  height: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tickIcon {
  width: 30px;
  height: 20px;
  display: block;
}

.downIcon {
  width: 40px;
  height: 50px;
  display: block;
}

.TCol-Progress2 {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Chrome, Safari, Edge, Opera */
.inputNumberNoSpin 
  input[type="number"]::-webkit-outer-spin-button, 
  input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none ;
  margin: 0 ;
}

/* Firefox */
.inputNumberNoSpin  input[type="number"] {
  -moz-appearance: textfield ;
}




@for $i from 1 through 12 {
  @for $s from 1 through 12-$i+1 {
    .Col2-#{$i}-#{$s} {
      color:rgb(235, 106, 240);
    }
    /* for click to choose */
    .Col3-#{$i}-#{$s} {
      color:rgb(35, 189, 73);
    }
  }
}




/* Theme */
$Theme_width0a: 95%;
$Theme_width0b: 95%;
$Theme_background-color0: rgb(255, 255, 255);
$Theme_color0: rgb(0, 0, 0);
$Theme_h4-heading_color0: rgb(233, 119, 181);
$Theme_Col2_color0: rgb(235, 106, 240);
$Theme_Col3_color0: rgb(11, 146, 7);

$Theme_width1a: 95%;
$Theme_width1b: 95%;
$Theme_background-color1: rgb(201, 252, 234);
$Theme_color1: rgb(0, 0, 0);
$Theme_h4-heading_color1: rgb(233, 119, 181);
$Theme_Col2_color1: rgb(235, 106, 240);
$Theme_Col3_color1: rgb(11, 146, 7);

$Theme_width2a: 95%;
$Theme_width2b: 95%;
$Theme_background-color2: rgb(174, 247, 171);
$Theme_color2: rgb(0, 0, 0);
$Theme_h4-heading_color2: rgb(233, 119, 181);
$Theme_Col2_color2: rgb(235, 106, 240);
$Theme_Col3_color2: rgb(22, 125, 221);

$Theme_width3a: 95%;
$Theme_width3b: 95%;
$Theme_background-color3: rgb(241, 243, 94);
$Theme_color3: rgb(0, 0, 0);
$Theme_h4-heading_color3: rgb(45, 150, 219);
$Theme_Col2_color3: rgb(235, 106, 240);
$Theme_Col3_color3: rgb(40, 141, 37);

$Theme_width4a: 95%;
$Theme_width4b: 95%;
//$Theme_background-color4: rgb(148, 139, 12);
$Theme_background-color4: #bdb07b;
$Theme_color4: rgb(255, 255, 255);
$Theme_h4-heading_color4: rgb(129, 201, 219);
$Theme_Col2_color4: rgb(235, 106, 240);
$Theme_Col3_color4: rgb(99, 216, 95);

$Theme_width5a: 95%;
$Theme_width5b: 95%;
$Theme_background-color5: rgba(228, 69, 83, 0.918);
$Theme_color5: rgb(255, 255, 255);
$Theme_h4-heading_color5: rgb(53, 143, 202);
$Theme_Col2_color5: rgb(235, 106, 240);
$Theme_Col3_color5: rgb(55, 207, 50);

$Theme_width6a: 95%;
$Theme_width6b: 95%;
$Theme_background-color6: rgba(241, 164, 47, 0.952);
$Theme_color6: rgb(255, 255, 255);
$Theme_h4-heading_color6: rgb(53, 143, 202);
$Theme_Col2_color6: rgb(235, 106, 240);
$Theme_Col3_color6: rgb(48, 155, 44);

$Theme_width7a: 95%;
$Theme_width7b: 95%;
$Theme_background-color7: rgba(209, 91, 238, 0.918);
$Theme_color7: rgb(255, 255, 255);
$Theme_h4-heading_color7: rgb(57, 138, 231);
$Theme_Col2_color7: rgb(235, 106, 240);
$Theme_Col3_color7: rgb(24, 116, 22);

$Theme_width8a: 95%;
$Theme_width8b: 95%;
$Theme_background-color8: rgb(255, 255, 255);
$Theme_color8: rgb(0, 0, 0);
$Theme_h4-heading_color8: rgb(233, 119, 181);
$Theme_Col2_color8: rgb(235, 106, 240);
$Theme_Col3_color8: rgb(11, 146, 7);


@mixin cardTheme($cardNo, $ThemeNo) {
  @if $ThemeNo == 0 {
    @if $cardNo == 1 {
      width: $Theme_width0a;
    } @else {
      width: $Theme_width0b;
    }
    
    background-color: $Theme_background-color0;
    color: $Theme_color0;

    .h4-heading
    {
      color: $Theme_h4-heading_color0;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color0;
        }
        // for click to choose
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color0;
        }
      }
    }
    

  } @else if $ThemeNo == 1 {
    @if $cardNo == 1 {
      width: $Theme_width1a;
    } @else {
      width: $Theme_width1b;
    }
    background-color: $Theme_background-color1;
    color: $Theme_color1;

    .h4-heading
    {
      color: $Theme_h4-heading_color1;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color1;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color1;
        }
      }
    }


  } @else if $ThemeNo == 2 {
    @if $cardNo == 1 {
      width: $Theme_width2a;
    } @else {
      width: $Theme_width2b;
    }
    background-color: $Theme_background-color2;
    color: $Theme_color2;

    .h4-heading
    {
      color: $Theme_h4-heading_color2;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color2;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color2;
        }
      }
    }


  } @else if $ThemeNo == 3 {
    @if $cardNo == 1 {
      width: $Theme_width3a;
    } @else {
      width: $Theme_width3b;
    }
    background-color: $Theme_background-color3;
    color: $Theme_color3;

    .h4-heading
    {
      color: $Theme_h4-heading_color3;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color3;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color3;
        }
      }
    }


  } @else if $ThemeNo == 4 {
    @if $cardNo == 1 {
      width: $Theme_width4a;
    } @else {
      width: $Theme_width4b;
    }
    background-color: $Theme_background-color4;
    color: $Theme_color4;

    .h4-heading
    {
      color: $Theme_h4-heading_color4;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color4;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color4;
        }
      }
    }
    
    #Err_msg {
      color: rgb(185, 8, 8);
    }  


  } @else if $ThemeNo == 5 {
    @if $cardNo == 1 {
      width: $Theme_width5a;
    } @else {
      width: $Theme_width5b;
    }
    background-color: $Theme_background-color5;
    color: $Theme_color5;

    .h4-heading
    {
      color: $Theme_h4-heading_color5;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color5;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color5;
        }
      }
    }

    #Err_msg {
      color: rgb(185, 8, 8);
    }  


  } @else if $ThemeNo == 6 {
    @if $cardNo == 1 {
      width: $Theme_width6a;
    } @else {
      width: $Theme_width6b;
    }
    background-color: $Theme_background-color6;
    color: $Theme_color6;

    .h4-heading
    {
      color: $Theme_h4-heading_color6;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color6;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color6;
        }
      }
    }


  } @else if $ThemeNo == 7 {
    @if $cardNo == 1 {
      width: $Theme_width7a;
    } @else {
      width: $Theme_width7b;
    }
    background-color: $Theme_background-color7;
    color: $Theme_color7;

    .h4-heading
    {
      color: $Theme_h4-heading_color7;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          color: $Theme_Col2_color7;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          color: $Theme_Col3_color7;
        }
      }
    }


  } @else if $ThemeNo == 8 {
    @if $cardNo == 1 {
      width: $Theme_width8a;
    } @else {
      width: $Theme_width8b;
    }

    //background-color: $Theme_background-color8;
    //color: $Theme_color8;

    .h4-heading
    {
      //color: $Theme_h4-heading_color8;
    }

    @for $i from 1 through 13 {
      @for $s from 1 through 13-$i+1 {
        .Col2-#{$i}-#{$s} {
          //color: $Theme_Col2_color8;
        }
        /* for click to choose */
        .Col3-#{$i}-#{$s} {
          //color: $Theme_Col3_color8;
        }
      }
    }
  }

}


@for $j from 0 through 10 {
  .cardOpen01-Theme#{$j} {
    @include cardTheme(1, $j)
  }

  .cardOpen02-Theme#{$j} {
    @include cardTheme(2, $j)
  }
}



