@import '~normalize.css/normalize.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
//@import '~@blueprintjs/table/lib/css/table.css';
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";


//$app-width: 1280px;
$app-width: 98%;
$app-width-min: 1024px;
$app-margin-v: 0px;
$app-margin-h: 8px;
$column-gap: 8px;

/*
body {
  background-color: $pt-app-background-color;
}
*/

body.#{$ns}-dark {
  background-color: $pt-dark-app-background-color;
}

.#{$ns}-input:read-only {
  background-color: $light-gray4;
}
/*
.#{$ns}-input:not(:read-only) {
  background-color: $white;
}
*/
.#{$ns}-dark .#{$ns}-input:read-only {
  background-color: $dark-gray4;
}
/*
.#{$ns}-dark .#{$ns}-input:not(:read-only) {
  background-color: $dark-gray2;
}
*/

.App {
  text-align: center;
  width: $app-width;
  min-height: 100%;
  min-width: $app-width-min;
  margin: 0 auto;
}

.AppLogo {
  width: 24px;
  height: 24px;
}

@for $i from 1 through 5 {
  .Spacing-V-#{$i*8} {
    height: $i*8px
  }
}

.Spacing-V-Menu {
  height: 50px
}


.AppBody {
  text-align: left;
  margin: $app-margin-v $app-margin-h;
}

.Row-8 {
  display: grid;
  column-gap: $column-gap;
  grid-template-columns: repeat(8, 1fr);
}
// 12 columns grid
.Row {
  display: grid;
  column-gap: $column-gap;
  grid-template-columns: repeat(12, 1fr);
}
@for $i from 1 through 12 {
  @for $s from 1 through 12-$i+1 {
    .Col-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
    .Col2-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
    .Col3-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
  }
}
.Col-H {
  display: flex;
  flex-direction: row;
}
.Col-H > *:not(:first-child) {
  margin-left: $column-gap;
}


.Button-LabeledFormGroup {
  margin-top: 22px;
  margin-bottom: 16px;
}

.Checkbox-LabeledFormGroup {
  margin-top: 28px;
  margin-bottom: 16px;
}

.Input-XS {
  width: 45px;
}

.Align-C {
  text-align: center;
  justify-content: center;
}
.Align-L {
  text-align: left;
}
.Align-R {
  text-align: right;
}

.Column-Sum {
  border-top:2px solid rgba(0,0,0,0.99);   
}

.TCol-60 {
  width: 60px;
}

.TCol-100 {
  width: 100px;
}

.TCol-150 {
  width: 150px;
}

.TCol-200 {
  width: 200px;
}

.TCol-350 {
  width: 350px;
}

.TCol-ACCode {
  width: 100px;
}
.TCol-ACName {
  width: 300px;
}

.TCol-ACName2 {
 
  justify-content:flex-end;
  width: 450px;
}

.TCol-IDNo {
  width: 150px;
}
.TCol-Tel {
  width: 150px;
}
.TCol-AECode {
  width: 100px;
}
.TCol-Status {
  width: 60px;
}

.TCol-StkName {
  width: 350px;
}

.TCol-APrice{
  width: 150px;
}

.TCol-TICK {
  width: 16px;
}
 
//Controlling Person
.TCol-Type_Of_Entity{
  width: auto;
}
.TCol-TYPE_OF_CONTROLLING {
  width: 500px;
}
.TCol-Entity {
  width: 100px;
}

 

@for $i from 50 through 200 {

  .Table1-#{$i} {
    text-align: right;
    width:  $i* 1px; 
    //font-size: smaller;
    //font-size: x-small;
   // /font-size: 50%;
    //justify-content:flex-end;
  // word-wrap:normal;
    //border-bottom:2px solid #005000;
  }
  .Table1-#{$i}  table thead {
  
    background-color: #10baed;
  // text-align: right;
  }

  
  .Table1-#{$i}  table thead {
  
    background-color: #10baed;
  // text-align: right;
  }
  

  .Table1-#{$i}  table thead th{
  
    background-color: #10baed;
    text-align: right;
  }
  

  .Table1-#{$i} tbody tr:nth-child(odd) td {
    background-color: #abdeee;
    .#{$ns}-dark & {
      background-color: $dark-gray4;
    }
  }
  
  .Table1-#{$i} tbody tr:nth-child(even) td {
    background-color: #CEECF5;
    .#{$ns}-dark & {
      background-color: $dark-gray3;
    }
  }
  
  .Table1-#{$i} tbody tr.highlight td {
    background-color: #F2F5A9;
  }
  .Table1-bottom-#{$i} {
    border-top:2px solid rgba(0,0,0,0.99);   
  }
  .Table1-bottom2-#{$i} {
    //border-top:2px solid rgba(0,0,0,0.99);   
    border-bottom:2px solid rgba(3, 122, 240, 0.99);     
    background-color: #ed1010;
  }




  /*
  .clnPosEntry-STK-#{$i} a{
    text-align: left;
    width: auto;
    //justify-content:flex-end;
    word-wrap:normal;
    border-bottom:2px solid #005000;
  }
   
  .clnPosEntry-STK-#{$i} {
    text-align: right;
    width:  $i*1px; 
    font-size: smaller;
    //justify-content:flex-end;
     word-wrap:normal;
    //border-bottom:2px solid #005000;
  }
   
 
  
  .clnPosEntry-STK-#{$i}  table thead {
    
    background-color: #10baed;
    text-align: right;
  }
   
  
  .clnPosEntry-STK-#{$i}  table thead {
    
    background-color: #10baed;
    text-align: right;}
  
  
  .clnPosEntry-STK-#{$i} tbody tr:nth-child(odd) td {
    background-color: #abdeee;
  }
  
  .clnPosEntry-STK-#{$i} tbody tr:nth-child(even) td {
    background-color: #CEECF5;
  }
  
  .clnPosEntry-STK-#{$i} tbody tr.highlight td {
    background-color: #F2F5A9;
  }
  
  
  
  .clnPosEntry-STK-#{$i} tbody tr.highlight td[colspan="8"] {
    text-align: center;
  }

*/
}
 
@for $i from 50 through 150 {

  .Table2-#{$i} {
    text-align: right;
    width:  $i* 1px; 
    //font-size: smaller;
    //font-size: x-small;
   // /font-size: 50%;
    //justify-content:flex-end;
  // word-wrap:normal;
    //border-bottom:2px solid #005000;
  }
  .Table2-#{$i}  table thead {
  
    background-color: #10baed;
  // text-align: right;
  }

  
  .Table2-#{$i}  table thead {
  
    background-color: #10baed;
  // text-align: right;
  }
  

  .Table2-#{$i}  table thead th{
  
    background-color: #10baed;
    text-align: right;
  }
  
 /*
  .Table2-#{$i} tbody tr:nth-child(odd) td {
    background-color: #abdeee;
  }
  
  .Table2-#{$i} tbody tr:nth-child(even) td {
    background-color: #CEECF5;
  }
*/
  .Table2-#{$i}.odd table tbody tr td {
    background-color: #da5d1a;
  }
  
  .Table2-#{$i}.even table tbody tr td {
    background-color: #4bd11e;
  }
 

 
  .Table2-#{$i} tbody tr.highlight td {
    background-color: #F2F5A9;
  }
  .Table2-bottom-#{$i} {
    border-top:2px solid rgba(0,0,0,0.99);   
  }

  .Table2-top-#{$i} {
    border-bottom:2px solid rgba(0,0,0,0.99);   
  }


  /*
  .clnPosEntry-STK-#{$i} a{
    text-align: left;
    width: auto;
    //justify-content:flex-end;
    word-wrap:normal;
    border-bottom:2px solid #005000;
  }
   
  .clnPosEntry-STK-#{$i} {
    text-align: right;
    width:  $i*1px; 
    font-size: smaller;
    //justify-content:flex-end;
     word-wrap:normal;
    //border-bottom:2px solid #005000;
  }
   
 
  
  .clnPosEntry-STK-#{$i}  table thead {
    
    background-color: #10baed;
    text-align: right;
  }
   
  
  .clnPosEntry-STK-#{$i}  table thead {
    
    background-color: #10baed;
    text-align: right;}
  
  
  .clnPosEntry-STK-#{$i} tbody tr:nth-child(odd) td {
    background-color: #abdeee;
  }
  
  .clnPosEntry-STK-#{$i} tbody tr:nth-child(even) td {
    background-color: #CEECF5;
  }
  
  .clnPosEntry-STK-#{$i} tbody tr.highlight td {
    background-color: #F2F5A9;
  }
  
  
  
  .clnPosEntry-STK-#{$i} tbody tr.highlight td[colspan="8"] {
    text-align: center;
  }

*/
}

 
 

/*
.TCol-CCY {
  
  text-align: center;
 
}

.clnPosEntry-STK a{
  text-align: left;
  width: auto;
  //justify-content:flex-end;
  word-wrap:normal;
  border-bottom:2px solid #005000;
}

.clnPosEntry-foot {
  text-align: center;
}
*/

.MenuIcon {
  width: 16px;
  height: 16px;
}
.MenuTile {
  text-align: center;
  font-weight: bold;
}
.MenuTile:hover {
  transform: scale(1.1);
}
.MenuTileImage {
  width: 60%;
  height: 50%;
}

@media print {
  .No-Print {
    display: none !important;
  }

  .Print-Align-C {
    margin: auto;
  }

  .Print-Align-C a {
    color: #182026;
  }

  .Print-Align-C td {
    padding: 3px 11px !important;
  }

  .Print-90-Percent * {
    font-size: 90% !important;
  }
  
  * {
      -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
      color-adjust: exact !important;  /*Firefox*/
  }

  .Page-Break-Before {
    page-break-before: always;
  }
  .Page-Break-After {
    page-break-after: always;
  }
}

@media screen {
  .No-Screen {
    display: none !important;
  }
}

/* fixed render dom problem in toast */
.bp4-toast {
    position: fixed !important;
  }