
.TCol-Dividend_Amount {
    width: 120px;
}

.TCol-Dividend_Collection_Fee {
    width: 80px;
}
.TCol-Scrip_Fee {
    width: 100px;
}
.TCol-Dividend_Handling_Fee {
    width: 80px;
}
.TCol-Net_Dividend_Amount {
    width: 120px;
}
.TCol-Qty_Reserved_for_Div_to_Bonus {
    width: 120px;
}
.TCol-Print_Div_Cheque_Option {
    width: 100px;
}
