.TCol-Start {
    width: 80px;
}
.TCol-Task {
    width: 400px;
}
.TCol-Progress {
    width: 400px;
}
.TCol-Message {
    width: 150px;
}
.TCol-End {
    width: 80px;
}
.TCol-Log {
    width: 100px;
    overflow: auto;
}
.Align-R {
    text-align: right;
}